<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item">
                <input-number
                    v-model="searchOptions.year"
                    :allowDot="false"
                    :allowMinus="false"
                    :displayComma="false"
                    :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회사명</div>
            <ul class="content">
              <li class="item">
                <input-text
                    v-model="searchOptions.companyName"
                    :allowDot="false"
                    :allowMinus="false"
                    :displayComma="false"
                    :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">이용권 발행 현황 목록</div>
              <div class="header-caption">[{{ dataSource.length }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="onPrintButtonClicked"
                  >
                    인쇄
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="ticketPublishCurrentStatusGrid"
                  v-bind="gridOptions"
                  :dataSource="dataSource"
                  :allowExcelExport="true"
                  @queryCellInfo="onQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>

import moment from "moment/moment";
import InputNumber from "@/components/common/InputNumber.vue";
import InputText from "@/components/common/text/InputText";
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {Aggregate, ExcelExport, Filter, ForeignKey, Group, Resize} from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {mapGetters} from "vuex";
import ReportView from '@/components/common/report/ReportView';

export default {
  name: "TicketPublishCurrentStatus",
  components: {
    InputNumber,
    InputText,
    ErpButton,
    ejsGridWrapper,
    ReportView,
  },
  mixins: [confirmDialogMixin],
  data() {
    return {
      searchOptions: {
        year: moment().year(),
        companyName: '',
      },
      dataSource: [],
      gridOptions: {
        provides: [ Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey],
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        allowResizing: true,
        allowGrouping: false,
        columns: [
          {
            headerText: "회사명",
            field: "memberName",
            textAlign: 'center',
            width: 100,
            type: "string",
          },
          {
            headerText: "구분",
            field: "bsnCode",
            textAlign: 'center',
            width: 100,
            type: "string",
            isCommonCodeField: true,
            groupCode: 'BSN_CODE',
          },
          {
            headerText: (moment().year() - 1) + "년",
            columns: [
              {
                headerText: "이용권 발행수량",
                columns: [
                  {
                    headerText: "수량",
                    field: "couponCntByPreYear",
                    textAlign: "right",
                    width: 100,
                    type: "number",
                    format: "N",
                  },
                  {
                    headerText: "합계",
                    field: "couponCntByPreYearTotal",
                    textAlign: "right",
                    width: 100,
                    type: "number",
                    format: "N",
                  },
                ]
              },
              {
                headerText: "금액",
                columns: [
                  {
                    headerText: "금액",
                    field: "couponAmtByPreYear",
                    textAlign: "right",
                    width: 100,
                    type: "number",
                    format: "N",
                  },
                  {
                    headerText: "합계",
                    field: "couponAmtByPreYearTotal",
                    textAlign: "right",
                    width: 100,
                    type: "number",
                    format: "N",
                  },
                ]
              },
            ]
          },
          {
            headerText: (moment().year()) + "년",
            columns: [
              {
                headerText: "이용권 발행수량",
                columns: [
                  {
                    headerText: "수량",
                    field: "couponCntByYear",
                    textAlign: "right",
                    width: 100,
                    type: "number",
                    format: "N",
                  },
                  {
                    headerText: "합계",
                    field: "couponCntByYearTotal",
                    textAlign: "right",
                    width: 100,
                    type: "number",
                    format: "N",
                  },
                ]
              },
              {
                headerText: "금액",
                columns: [
                  {
                    headerText: "금액",
                    field: "couponAmtByYear",
                    textAlign: "right",
                    width: 100,
                    type: "number",
                    format: "N",
                  },
                  {
                    headerText: "합계",
                    field: "couponAmtByYearTotal",
                    textAlign: "right",
                    width: 100,
                    type: "number",
                    format: "N",
                  },
                ]
              },
            ]
          },
          {
            headerText: "증감",
            field: "couponAmtIncDec",
            textAlign: "right",
            width: 100,
            type: "number",
            format: "N",
          },
          {
            headerText: "이용권 번호",
            field: "ticketNumber",
            textAlign: "center",
            width: 90,
            type: "string",
          },
          {
            headerText: "비고",
            field: "remarks",
            textAlign: "center",
            width: 90,
            type: "string",
          },
        ],
        aggregates: [
          {
            columns: [
              {
                field: "memberName",
                aggregationType: "TotalCaption",
                customAggregate: "소계",
              },
              {
                field: "bsnCode",
                aggregationType: "TotalCaption",
                customAggregate: "주중",
              },
                // 작년 주중 - 이용권 발행수량 합계
              {
                field: "couponCntByPreYear",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  const gridData = this.$refs.ticketPublishCurrentStatusGrid.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.bsnCode === "WEEKDAY" ? item.couponCntByPreYear : 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return sum;
                },
              },
                // 작년 - 이용권 발행수량 총합계
              {
                field: "couponCntByPreYearTotal",
                aggregationType: "TotalSum",
              },
              // 작년 주중 - 금액 합계
              {
                field: "couponAmtByPreYear",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  const gridData = this.$refs.ticketPublishCurrentStatusGrid.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.bsnCode === "WEEKDAY" ? item.couponAmtByPreYear : 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return sum;
                }
              },
              // 작년 - 금액 총합계
              {
                field: "couponAmtByPreYearTotal",
                aggregationType: "TotalSum",
              },
              // 금년 주중 - 이용권 발행수량 합계
              {
                field: "couponCntByYear",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  const gridData = this.$refs.ticketPublishCurrentStatusGrid.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.bsnCode === "WEEKDAY" ? item.couponCntByYear : 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return sum;
                }
              },
              // 금년 - 이용권 발행수량 총합계
              {
                field: "couponCntByYearTotal",
                aggregationType: "TotalSum",
              },
              // 금년 주중 - 금액 총합계
              {
                field: "couponAmtByYear",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  const gridData = this.$refs.ticketPublishCurrentStatusGrid.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.bsnCode === "WEEKDAY" ? item.couponAmtByYear : 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return sum;
                }
              },
              // 작년 주중 - 금액 총합계
              {
                field: "couponAmtByPreYear",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  const gridData = this.$refs.ticketPublishCurrentStatusGrid.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.bsnCode === "WEEKDAY" ? item.couponAmtByPreYear : 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return sum;
                }
              },
              {
                field: "couponAmtByYearTotal",
                aggregationType: "TotalSum",
              },
              {
                field: "couponAmtIncDec",
                aggregationType: "TotalSum",
              },
            ],
          },
          {
            columns: [
              {
                field: "bsnCode",
                aggregationType: "TotalCaption",
                customAggregate: "주말",
              },
              // 작년 주말 - 이용권 발행수량 합계
              {
                field: "couponCntByPreYear",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  const gridData = this.$refs.ticketPublishCurrentStatusGrid.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.bsnCode === "WEEKEND" ? item.couponCntByPreYear : 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return sum;
                }
              },
              // 작년 주말 - 금액 합계
              {
                field: "couponAmtByPreYear",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  const gridData = this.$refs.ticketPublishCurrentStatusGrid.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.bsnCode === "WEEKEND" ? item.couponAmtByPreYear : 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return sum;
                }
              },
              // 금년 주말 - 이용권 발행수량 합계
              {
                field: "couponCntByYear",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  const gridData = this.$refs.ticketPublishCurrentStatusGrid.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.bsnCode === "WEEKEND" ? item.couponCntByYear : 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return sum;
                }
              },
              // 금년 주말 - 금액 총합계
              {
                field: "couponAmtByYear",
                aggregationType: "TotalCaption",
                customAggregate: () => {
                  const gridData = this.$refs.ticketPublishCurrentStatusGrid.getBatchCurrentViewRecords();
                  const sum = gridData
                      .map(item => item.bsnCode === "WEEKEND" ? item.couponAmtByYear : 0)
                      .reduce((acc, cur) => cur + acc, 0);
                  return sum;
                }
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false;
    },
  },
  mounted() {
  },
  methods: {
    async onViewButtonClicked() {
      const gridData = await GolfErpAPI.fetchTicketPubStatus(this.searchOptions);

      // 회사명 기준으로 계산하기위해 중복 없는 회사명 추출
      const memberNames = [];
      gridData.forEach(item => {
        if (!memberNames.includes(item.memberName)){
          memberNames.push(item.memberName);
        }
      });

      // 회사명 기준으로 bsnCode : WEEKDAY, WEEKEND 순으로 순서 보장
      memberNames.forEach(name => {
        gridData.map((item, idx) => {
          const  {memberName} = item;
          if (name === memberName) {
            if ((idx === 0) || (idx % 2 === 0)) {
              if (item.bsnCode !== "WEEKDAY") {
                let tmp;
                tmp = gridData[idx];
                gridData[idx] = gridData[idx + 1];
                gridData[idx + 1] = tmp;
              }
            }
          }
        });
      });

      // 회사명 기준 첫번째 인덱스에 필요한 합계 필드 계산해서 넣음
      memberNames.forEach(name => {
        gridData.map((item, idx) => {
          const {memberName, couponCntByPreYear, couponAmtByPreYear, couponCntByYear, couponAmtByYear} = item;
          if (name !== memberName) {
            item.memberName = (idx === 0) || (idx % 2 === 0) ? memberName : "";
            // 세로 셀 병합 갯수
            item.rowSpan = (idx === 0) || (idx % 2 === 0) ? 2 : 0;
            // 세로 셀 병합 적용
            item.isRowSpan = (idx === 0) || (idx % 2 === 0);
            // 작년 - 이용권 발행수량 합계
            item.couponCntByPreYearTotal = (idx === 0) || (idx % 2 === 0) ?
                couponCntByPreYear + gridData[idx + 1]["couponCntByPreYear"] : 0;
            // 작년 - 금액 합계
            item.couponAmtByPreYearTotal = (idx === 0) || (idx % 2 === 0) ?
                couponAmtByPreYear + gridData[idx + 1]["couponAmtByPreYear"] : 0;
            // 올해 - 이용권 발행수량 합계
            item.couponCntByYearTotal = (idx === 0) || (idx % 2 === 0) ?
                couponCntByYear + gridData[idx + 1]["couponCntByYear"] : 0;
            // 올해 - 금액 합계
            item.couponAmtByYearTotal = (idx === 0) || (idx % 2 === 0) ?
                couponAmtByYear + gridData[idx + 1]["couponAmtByYear"] : 0;
            // 증감
            item.couponAmtIncDec = item.couponAmtByPreYearTotal - item.couponAmtByYearTotal;
            // 회사명 순으로 봤을 때 마지막 회사의 첫번째 인덱스 border-bottom 적용
            item.isLastRowSpanBorderBottom = idx === gridData.length - 2;
            item.div = item.bsnCode === "WEEKDAY" ? "주중" : "주말";
          }
        });
      });

      this.dataSource = gridData;

    },

    onPrintButtonClicked() {
      if(!this.dataSource.length) {
        this.errorToast(this.$t("report.popupMessage.noData"));
        return;
      }

      const reportData = {
            reportJson: {
              jsonData: {
                dataSource: this.dataSource,
              },
              username: this.username,
              uniqueCode: this.$options.name,
              title: `${moment().year()}년 골프 자유이용권 발행`,
              unit: `단위: 천원(${moment().format("YY")}.${ moment().month()} 기준)`,
              byPreYear: moment().year() - 1,
              byYear: moment().year(),
            },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onExcelButtonClicked() {
      if(!this.dataSource.length) {
        this.errorToast(this.$t("report.popupMessage.noData"));
        return;
      }
      this.$refs.ticketPublishCurrentStatusGrid.excelExport({ fileName: '이용권 발행 현황.xlsx' });
    },
    onQueryCellInfo(args) {
      const {
        cell,
        column: {field, type},
        data,
      } = args;
      if (type !== null && type.toLowerCase() === "number" && !data[field]) {
        cell.innerText = "-";
      }

      if (field === "memberName"
          || field === "couponCntByPreYearTotal"
          || field === "couponAmtByPreYearTotal"
          || field === "couponCntByYearTotal"
          || field === "couponAmtByYearTotal"
          || field === "couponAmtIncDec"
          || field === "ticketNumber"
          || field === "remarks"
      ) {
        if (data.isRowSpan) {
          args.rowSpan = data.rowSpan;
        }

        if (data.isLastRowSpanBorderBottom) {
          cell.style.borderBottom = "1px solid #D0D4CA";
        }

      }
    },
  },
};
</script>

